import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/Neutra2Text-Light.otf\",\"weight\":\"200\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Neutra2Text-LightItalic.otf\",\"weight\":\"200\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/Neutra2Text-Book.otf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Neutra2Text-BookItalic.otf\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/Neutra2Text-Demi.otf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Neutra2Text-DemiItalic.otf\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/Neutra2Text-Bold.otf\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Neutra2Text-BoldItalic.otf\",\"weight\":\"700\",\"style\":\"italic\"}],\"fallback\":[\"Geneva\",\"Verdana\",\"sans-serif\"]}],\"variableName\":\"neutra2Text\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/Reckless-Light.woff2\",\"weight\":\"200\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Reckless-Light.woff\",\"weight\":\"200\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Reckless-LightItalic.woff2\",\"weight\":\"200\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/Reckless-LightItalic.woff\",\"weight\":\"200\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/Reckless-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Reckless-Regular.woff\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Reckless-RegularItalic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/Reckless-RegularItalic.woff\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/Reckless-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Reckless-Medium.woff\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Reckless-MediumItalic.woff2\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/Reckless-MediumItalic.woff\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/Reckless-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Reckless-Bold.woff\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Reckless-BoldItalic.woff2\",\"weight\":\"700\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/Reckless-BoldItalic.woff\",\"weight\":\"700\",\"style\":\"italic\"}],\"fallback\":[\"Geneva\",\"Verdana\",\"Arial\",\"Helvetica\",\"sans-serif\"]}],\"variableName\":\"recklessText\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/TiemposHeadline-Regular.otf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/TiemposHeadline-RegularItalic.otf\",\"weight\":\"400\",\"style\":\"italic\"}],\"fallback\":[\"Times New Roman\",\"Times\",\"serif\"]}],\"variableName\":\"tiemposHeadline\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/Pilat-Heavy.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Pilat-Heavy.woff\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Pilat-Heavy.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/Pilat-Heavy.woff\",\"weight\":\"400\",\"style\":\"italic\"}],\"fallback\":[\"Times New Roman\",\"Times\",\"serif\"]}],\"variableName\":\"pilatHeadline\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloWrapper"] */ "/app/src/app/ApolloWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ProgressBarProvider/ProgressBarProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/lib/registry.tsx");
